import "./IntersectionalBotPopup.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import BotIcon from "../../icons/BotIcon/BotIcon";
import {Button} from "react-bootstrap";

type milliseconds = number

const DEFAULT_POP_DELAY: milliseconds = 2000;

interface IntersectionalBotPopupProps extends HTMLAttributes<HTMLDivElement> {
    onGoToBot?: () => void
    popUpDelay?: milliseconds
}

function IntersectionalBotPopup({popUpDelay, onGoToBot, ...props}: IntersectionalBotPopupProps) {
    const className = 'IntersectionalBotPopup ' + (props.className ?? '')
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        const showDelay = popUpDelay ?? DEFAULT_POP_DELAY

        const showTimeout = setTimeout(() => setShow(true), showDelay)

        return () => {
            clearTimeout(showTimeout)
        }
    }, [])

    const handleGoToBot = () => {
        onGoToBot?.()
        setShow(false)
    }

    const handleCancel = () => {
        setShow(false)
    }

    if (!show) {
        return null
    }

    return <div {...props} className={className}>
        <BotIcon className={'IntersectionalBotPopup-Icon'} size={'60px'}/>
        <div className={'Message-bot full-size-message'}>
            <h5>Hallo, ich bin JuriskopBot!</h5>
            <p>
                Es könnten weitere Motive vorliegen, die sich zwar nicht in der Strafbarkeit ausgewirkt haben, aber
                weiter relevant sind, z. B. Disrkiminierungsmerkmale: Möchtest du weiterere Begleitmotive, die Deiner
                Äußerung zugrunde liegen könnten, erfassen?
            </p>
            <div className={'IntersectionalBotPopup-Buttons'}>
                <Button variant={'primary'} onClick={handleGoToBot}>
                    Zum JuriskopBOT
                </Button>
                <Button variant={'outline-secondary'} onClick={handleCancel}>
                    Nein, danke
                </Button>
            </div>
        </div>
    </div>
}

export default IntersectionalBotPopup;