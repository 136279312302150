import "./Feedback.scss"
import React, {HTMLAttributes} from 'react';
import {FaClipboardList} from "react-icons/fa6";
import {Button} from "react-bootstrap";
import {SURVEY_LINK} from "../descriptions/SurveyLink/SurveyLink";
import ExpandableText from "../ExpandableText/ExpandableText";

interface FeedbackProps extends HTMLAttributes<HTMLDivElement> {
}

function Feedback({...props}: FeedbackProps) {
    const className = 'Feedback ' + (props.className ?? '')

    return <div {...props} className={className}>
        <ExpandableText titleElement={<h6>Gefällt Dir isthishatespeech.de?</h6>}>
            <p>
                Nimm gerne an folgender anonymen Umfrage teil und unterstütze somit auch unsere Forschung (Dauer: ca. 2 Minuten)
            </p>
        </ExpandableText>
        <Button variant={'primary'} href={SURVEY_LINK} target="_blank">
            Zur Umfrage <FaClipboardList/>
        </Button>
    </div>
}

export default Feedback;