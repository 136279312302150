import "./Question.scss"
import React, {HTMLAttributes} from 'react';
import {JunBotQuestion, JunBotQuestionTypes} from "../../../../types/junbot";
import YesNoQuestion from "./YesNoQuestion/YesNoQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion/MultipleChoiceQuestion";
import {FaSpinner} from "react-icons/fa";
import {AnswerMessage} from "../../IntersectionalBot";
import ViewBotMessage from "../ViewBotMessage";

export interface QuestionProps extends HTMLAttributes<HTMLDivElement> {
    question: JunBotQuestion
    onCreateAnswer?: (answer: AnswerMessage) => void
}

function Question({question, onCreateAnswer, ...props}: QuestionProps) {
    const className = 'Question ' + (props.className ?? '')

    if (question === undefined) {
        return <div {...props} className={className}>
            <FaSpinner className={'rotating'}/>
        </div>
    }

    let content = <div>
        Error displaying question with id '{question._id}': QuestionType '{question.values.type}' not known.
    </div>
    switch (question.values.type) {
        case JunBotQuestionTypes.YesNo:
            content =
                <YesNoQuestion question={question}
                               onCreateAnswer={onCreateAnswer}
                />
            break
        case JunBotQuestionTypes.MultipleChoice:
            content = <MultipleChoiceQuestion question={question}
                                              onCreateAnswer={onCreateAnswer}
            />
            break
    }

    return <ViewBotMessage {...props} className={className}>
        {content}
    </ViewBotMessage>
}

export default Question;