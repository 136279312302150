import "./SubsumeTweet.scss";
import React, { FormEventHandler, useEffect, useState, useRef } from "react";
import ChatGPTResult from "../../components/ChatGPT/ChatGPTResult/ChatGPTResult";
import { ChatGPTResponse } from "../../types/swishAPI";
import { useAPIConnector } from "../../hooks/useAPIConnector";
import { Button, Form } from "react-bootstrap";
import TwitterPipeline from "../../components/LoadingAnimations/TwitterPipeline/TwitterPipeline";
import ProcessingTimeIndicator from "../../components/ProcessingTimeIndicator/ProcessingTimeIndicator";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { TwitterSteps, useProgressionHandling } from "../../components/Twitter/Twitter";
import { useSubsumptionResult } from "../../hooks/twitterDataHooks/useSubsumptionResult";
import { AxiosError } from "axios";
import ViewNetworkError from "../../components/ViewNetworkError/ViewNetworkError";
import { useFactDescription } from "../../hooks/twitterDataHooks/useFactDescription";
import { TWEET_LINK_SEARCH_PARAM_KEX } from "../../components/input/modalities/FetchTweet/TweetInput/TweetInput";
import ExpandableText from "../../components/ExpandableText/ExpandableText";
import LogicValuePropertyIcon from "../../components/icons/LogicValuePropertyIcon/LogicValuePropertyIcon";
import BotIcon from "../../components/icons/BotIcon/BotIcon";
import ExampleList from "../../components/customLists/ExampleList/ExampleList";
import ExplanationList from "../../components/customLists/ExplanationList/ExplanationList";
import IconList from "../../components/customLists/IconList/IconList";

const EXPECTED_TIME_CHAT_GPT_REQUEST = 1.1 * 30 * 1000;
const CACHED_CHATGPT_RESPONSES_COOKIE_NAME = "Cached-ChatGPT-Responses";

function SubsumeTweet() {
    const { setStep, setCurProgression } = useProgressionHandling();
    const [factDescription] = useFactDescription();
    const { setLLMResult } = useSubsumptionResult();
    const [disclaimerChecked, setDisclaimerChecked] = useState<boolean>(false);
    const [validateDisclaimerCheckbox, setValidateDisclaimerCheckbox] =
        useState<boolean>(false);
    // avoid multiple ChatGPT requests
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [networkError, setNetworkError] = useState<AxiosError | null>(null);
    const api = useAPIConnector();

    const [cookies, setCookies] = useCookies([
        CACHED_CHATGPT_RESPONSES_COOKIE_NAME,
    ]);
    const [searchParams] = useSearchParams();

    const upperButtonRef = useRef<HTMLButtonElement>(null);

    const handleFocusClick = () => {
        if (upperButtonRef.current) {
            upperButtonRef.current.focus();
        }
    };

    useEffect(() => {
        setStep(TwitterSteps.SUBSUME);
    }, []);

    const gotoNextStep = () => {
        setStep(TwitterSteps.QUERY_SWISH);
    };

    useEffect(() => {
        if (factDescription === null) {
            return;
        }
        if (isLoading) {
            return;
        }
        setCurProgression(10);
        setIsLoading(true);

        // get current tweet pathname to find the appropriate key in the cache
        let tweetHandle: string | undefined = undefined;
        const tweetURLSearchParam = searchParams.get(TWEET_LINK_SEARCH_PARAM_KEX);
        if (factDescription.type === "tweet" && tweetURLSearchParam !== null) {
            const tweetURL = new URL(tweetURLSearchParam);
            tweetHandle = tweetURL.pathname;
        }

        let checkCache = true;
        if (factDescription.type !== "tweet") {
            checkCache = false;
        }

        // check for cached Response
        const cache: Record<string, ChatGPTResponse> =
            cookies[CACHED_CHATGPT_RESPONSES_COOKIE_NAME] ?? {};
        if (
            checkCache &&
            tweetHandle !== undefined &&
            cache.hasOwnProperty(tweetHandle)
        ) {
            const cachedResponse = cache[tweetHandle];
            // set timeout to visualize some kind of loading. Updating instantly feels weird somehow :D
            setTimeout(() => {
                setLLMResult(cachedResponse);
                setCurProgression(50);
                setIsLoading(false);
                console.log("Use cached ChatGPT response.");
            }, 500);
            return;
        }

        api
            .promptChatGPT(factDescription)
            .then((response) => {
                setLLMResult(response);
                setCurProgression(50);
                setIsLoading(false);
                setNetworkError(null);
                // update cache
                if (tweetHandle !== undefined) {
                    cache[tweetHandle] = response;
                    setCookies(CACHED_CHATGPT_RESPONSES_COOKIE_NAME, cache);
                }
            })
            .catch((error) => {
                console.error("Error querying the subsumption endpoint.");
                setIsLoading(false);
                setNetworkError(error);
            });
    }, [factDescription]);

    useEffect(() => {
        if (disclaimerChecked) {
            setCurProgression(100);
        } else {
            setCurProgression(50);
        }
    }, [disclaimerChecked]);

    const processingTimeMessage = (
        <div>
            {/* <p>
        Der von Dir bereitgestellte Inhalt wird nun - anhand von uns
        entwickelter Kriterien - von ChatGPT erfasst.
      </p> */}
            {/* <p>
        Dies kann bis eine Weile dauern.{" "}
        <strong>Bitte lade währenddessen die Seite nicht neu.</strong>
      </p> */}
        </div>
    );

    const disclaimer = (
        <div className={"disclaimer"}>
            Ich nehme zur Kenntnis, dass die folgende Sachverhaltserfassung maßgeblich
            auf der Analyse von ChatGPT beruht. Demnach können Fehler bei der
            Erfassung durch ChatGPT zu Fehlern im Ergebnis führen. Mir ist bewusst,
            dass eigenständig vorgenommene Änderungen zu einer Abweichung der
            Bewertung führen kann.
        </div>
    );

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        if (!disclaimerChecked) {
            setValidateDisclaimerCheckbox(true);
            setTimeout(() => setValidateDisclaimerCheckbox(false), 3000);
            return;
        }

        gotoNextStep();
    };

    if (networkError !== null) {
        return (
            <div className={"SubsumeTweet"}>
                <ViewNetworkError axiosError={networkError} />
            </div>
        );
    }

    return (
        <div className={"SubsumeTweet"}>
            <p>
                Der von Dir bereitgestellte Inhalt wird nun - anhand von uns
                entwickelter Kriterien - von ChatGPT erfasst.
            </p>

            <div className={"SubsumeTweet-Visualization"}>
                <TwitterPipeline
                    factDescription={factDescription}
                    isLoading={isLoading}
                    curStep={"ChatGPT"}
                />
            </div>
            <div className={"horizontal-separator"} role={"presentation"} />
            {isLoading && (
                <ProcessingTimeIndicator
                    expectedTime={EXPECTED_TIME_CHAT_GPT_REQUEST}
                    message={processingTimeMessage}
                />
            )}
            <Form
                className={"SubsumeTweet-NextStep"}
                noValidate={true}
                validated={validateDisclaimerCheckbox}
                onSubmit={handleSubmit}
            >
                {!isLoading && (
                    <Form.Check
                        type={"checkbox"}
                        checked={disclaimerChecked}
                        onChange={() => setDisclaimerChecked(!disclaimerChecked)}
                        id={"disclaimer"}
                        label={disclaimer}
                        required={true}
                        className={"disclaimer-check"}
                    />
                )}
                <Button
                    type={"submit"}
                    className={"submit-button"}
                    disabled={isLoading}
                    ref={upperButtonRef}
                >
                    Weiter: Rechtliche Einordnung der Antwort von ChatGPT
                </Button>
            </Form>
            <p>
                Du kannst nun direkt zur rechtlichen Einordnung weitergehen.{" "}
                <strong>Kannst Du es besser als ChatGPT?</strong> Passe die Bewertung
                der Kriterien mithilfe der juristischen Hinweise unseres
                Expert*innen-Teams an.
            </p>

            <ExpandableText
                titleElement={<strong>Weitere Hinweise</strong>}
                className={"SubsumeTweet-legend"}
            >
                Zum Korrigieren verwende die Knöpfe
                <IconList
                    bullet={<LogicValuePropertyIcon value={"true"} />}
                    elements={[<span>Vorliegen eines Kriteriums</span>]}
                />
                <IconList
                    bullet={<BotIcon />}
                    elements={[<span>Entscheidung ChatGPT</span>]}
                />
                <IconList
                    bullet={<LogicValuePropertyIcon value={"false"} />}
                    elements={[<span>Kriterium nicht erfüllt</span>]}
                />
                <ExplanationList explanations={["Rechtlicher Hintergrund"]} />
                <ExampleList type={"positive"} examples={["Positive Beispiele"]} />
                <ExampleList type={"negative"} examples={["Negative Beispiele"]} />
            </ExpandableText>

            <div className={"SubsumeTweet-Result"}>
                <h4>Einschätzung durch ChatGPT :</h4>
                <ChatGPTResult editable={true} />
            </div>
            <Button
                type={"submit"}
                className={"submit-button"}
                disabled={isLoading}
                onClick={handleFocusClick}
            >
                Jetzt zur rechtlichen Einordnung
            </Button>
        </div>
    );
}

export default SubsumeTweet;
