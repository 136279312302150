import "./ResultSideBar.scss"
import React, {HTMLAttributes, useContext, useState} from 'react';
import Feedback from "../Feedback/Feedback";
import {Button} from "react-bootstrap";
import {FaBars, FaBell, FaX} from "react-icons/fa6";
import BotIcon from "../icons/BotIcon/BotIcon";
import ExpandableText from "../ExpandableText/ExpandableText";
import {IsMobile} from "../../context/IsMobile";

interface ResultSideBarProps extends HTMLAttributes<HTMLDivElement> {
    intersectionalBotRef?: React.RefObject<HTMLDivElement>;
}

function ResultSideBar({intersectionalBotRef, ...props}: ResultSideBarProps) {
    const isMobile = useContext(IsMobile)
    const [isExpanded, setIsExpanded] = useState<boolean>(!isMobile)
    const className = 'ResultSideBar ' + (props.className ?? '')
    const classNameModifiers = `${isExpanded ? 'expanded' : 'collapsed'} ${isMobile ? 'mobile' : 'desktop'}`

    const handleClickGoToBot = () => {
        if (!intersectionalBotRef) {
            return
        }
        intersectionalBotRef.current?.scrollIntoView()
    }

    let sidebarContent;

    if (isExpanded) {
        sidebarContent = [
            <button className={'ResultSideBar-btn button-no-color'} onClick={() => setIsExpanded(false)}>
                <FaX aria-label={'schließe Seitenreiter'}/>
            </button>,
            // Allow user to provide feedback
            <Feedback/>,
            <div className={'horizontal-separator'}></div>,
            // Register in mailing list
            <div className={'StayUpdatedDialog'}>
                <ExpandableText titleElement={<h6>Bleib' auf dem neusten Stand</h6>}>
                    <p>
                        Hinterlasse deine E-Mail-Adresse damit wir dich auf dem aktuellen Stand informieren können, z.
                        B.
                        wenn schon bald eine neue Version mit spannenden Features zur Verfügung steht!
                    </p>
                </ExpandableText>
                <Button className={'StayUpdatedDialog-button'}
                        href={'mailto:info@juriskop.de?subject=[isthishatespeech] Auf dem neuesten Stand bleiben&body=Hi, ich möchte gerne über Aktualisierungen und neue Versionen von isthishatespeech informiert werden.'}>
                    <FaBell aria-label={'mailto:info@juriskop.de'}/>
                </Button>
            </div>,
            <div className={'horizontal-separator'}/>,
            // Shortcut to JuriskopBot
            <div className={'GoToJuriskopBot'}>
                <ExpandableText titleElement={<h6>Zum JuriskopBot</h6>}>
                    <p>
                        Es könnten weitere Motive vorliegen, die sich zwar nicht in der Strafbarkeit ausgewirkt haben,
                        aber
                        weiter relevant sind, z. B. Disrkiminierungsmerkmale: Möchtest du weiterere Begleitmotive, die
                        Deiner Äußerung zugrunde liegen könnten, erfassen?
                    </p>
                </ExpandableText>
                <Button className={'GoToJuriskopBot-button'} onClick={handleClickGoToBot}>
                    <BotIcon/>
                </Button>
            </div>
        ]
    } else {
        sidebarContent = [<button className={'ResultSideBar-btn button-no-color'} onClick={() => setIsExpanded(true)}>
            <FaBars aria-label={'öffne Seitenreiter'}/>
        </button>]
    }

    return <div {...props} className={className + ` ${classNameModifiers}`}>
        <div className={`ResultSideBar-container rounded-container ${classNameModifiers}`}>
            {sidebarContent}
        </div>
    </div>
}

export default ResultSideBar;