import axios, {GenericAbortSignal} from "axios";
import {useCallback, useContext, useMemo} from "react";
import {
    ChatGPTResponse,
    ChatGPTResponsePropertyDescription, revisedChatGPTResponse,
    SwishAPIResponseJSON
} from "../types/swishAPI";
import mockTwitterHatespeechAPI from "../test/mockTwitterHatespeechAPI";
import {APIContext} from "../context/APIContext";
import {useAuth} from "react-oidc-context";
import {FactDescriptionInput} from "../types/types";
import {generateFactDescription} from "../functions/factDescription/generateFactDescription";


/**
 * This is the connector to hatespeech API backend server.
 */
export const useAPIConnector = () => {
    const apiCTX = useContext(APIContext)
    const auth = useAuth()

    const connection = useMemo(() => axios.create({
        baseURL: process.env.REACT_APP_TWITTER_HATESPEECH_API_URL,
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    }), [auth.user])

    // mock the connector if appropriate flag is set
    if (process.env.REACT_APP_MOCK_TWITTER_HATESPEECH_API !== '0') {
        mockTwitterHatespeechAPI(connection)
    }

    /**
     *
     */
    const promptChatGPT = useCallback(async (factDescription: FactDescriptionInput, abortSignal?: GenericAbortSignal): Promise<ChatGPTResponse> => {


        let response = await connection.post(
            "/promptChatGPT",
            {
                content: generateFactDescription(factDescription)
            },
            {
                headers: {
                    'X-Conversation-UUID': apiCTX.uuid
                },
                signal: abortSignal
            }
        );
        const uuid = response.headers['x-conversation-uuid']
        apiCTX.setUUID(uuid)
        return response.data
    }, [connection, apiCTX])

    /**
     *
     */
    const getDescription = useCallback(async (abortSignal?: GenericAbortSignal): Promise<ChatGPTResponsePropertyDescription> => {
        let response = await connection.get(
            "/description",
            {
                signal: abortSignal
            }
        );
        let descriptionMap = await response.data;

        // remove these properties as we hardcode them
        delete descriptionMap.insult185.children.context
        delete descriptionMap.insult185.children.weighingUp
        delete descriptionMap.intent
        delete descriptionMap.offendersAge

        // remove property for data privacy reasons
        delete descriptionMap.victim.victimName

        return descriptionMap
    }, [connection])

    /**
     *
     */
    const querySwish = useCallback(async (swishInput: revisedChatGPTResponse, abortSignal?: GenericAbortSignal): Promise<SwishAPIResponseJSON> => {
        // hardcode properties
        swishInput.swishAPIRequestJSON.insult185.context = "true"
        swishInput.swishAPIRequestJSON.insult185.weighingUp = "true"
        swishInput.swishAPIRequestJSON.offendersAge = 14
        swishInput.swishAPIRequestJSON.intent = "true"

        let response = await connection.post(
            "/querySwish",
            swishInput,
            {
                headers: {
                    'X-Conversation-UUID': apiCTX.uuid
                },
                signal: abortSignal
            }
        );
        return await response.data;
    }, [connection, apiCTX.uuid])

    const setQualityInput = useCallback((isQualityInput: boolean, abortSignal?: GenericAbortSignal): Promise<any> => {
        return connection.post(
            "/qualityInput",
            {
                qualityInput: isQualityInput
            },
            {
                headers: {
                    'X-Conversation-UUID': apiCTX.uuid
                },
                signal: abortSignal
            }
        )
    }, [connection, apiCTX.uuid])

    const hideNonSensical = useCallback(async (chatGPTResult: ChatGPTResponse, abortSignal?: GenericAbortSignal): Promise<string[]> => {
        let response = await connection.post(
            "/hideNonsensicals",
            chatGPTResult,
            {
                headers: {
                    'X-Conversation-UUID': apiCTX.uuid
                },
                signal: abortSignal
            }
        )

        return response.data
    }, [connection, apiCTX.uuid])

    return useMemo(() => {
        return {promptChatGPT, getDescription, querySwish, setQualityInput, hideNonSensical}
    }, [promptChatGPT, getDescription, querySwish, setQualityInput, hideNonSensical])
}