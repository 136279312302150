import "./SurveyLink.css";
import React, {HTMLAttributes} from "react";
import {Button} from "react-bootstrap";
import {FaClipboardList} from "react-icons/fa6";

export const SURVEY_LINK = "https://survey.juriskop.de/index.php/545594?newtest=Y&lang=de"

interface SurveyLinkProps extends HTMLAttributes<HTMLDivElement> {
}

function SurveyLink({...props}: SurveyLinkProps) {
    const className = "SurveyLink " + (props.className ?? "");

    return (
        <div {...props} className={className}>
            <h2>Du möchtest unsere Forschung unterstützen?</h2>

            <div className={"SurveyLink-content"}>
                <p>
                    Dann beantworte bitte folgende anonyme Umfrage zum Nutzungserlebnis
                    mit unserem Hatespeech-Bot (Dauer: ca. 2 Minuten).
                </p>
                <Button
                    href={SURVEY_LINK}
                    target={"_blank"}
                    variant={"primary"}
                >
                    Zur Umfrage <FaClipboardList/>
                </Button>
            </div>
        </div>
    );
}

export default SurveyLink;
