import "./ViewSingleProperty.scss";
import React, {HTMLAttributes, useEffect, useState} from "react";
import {
    LogicValue,
    SinglePropertyDescription,
} from "../../../../../types/swishAPI";
import {ChatGPTResponseValueType} from "../../../../../context/ChatGPTResponseCTX";
import {FaQuestionCircle} from "react-icons/fa";
import ViewLogicValue from "./ViewLogicValueProperty/ViewLogicValue";
import {Badge, Button, Form} from "react-bootstrap";
import {
    ageToCriminalResponsibility,
    criminalResponsibilityToAge,
    OFFENDERS_AGE_DISPLAY_NAME,
} from "../../../../../functions/offendersAgeTransform";
import {useSubsumptionResult} from "../../../../../hooks/twitterDataHooks/useSubsumptionResult";
import ExplanationList from "../../../../customLists/ExplanationList/ExplanationList";
import ExampleList from "../../../../customLists/ExampleList/ExampleList";
import {FaAngleDown, FaAngleUp} from "react-icons/fa6";

export interface ViewSinglePropertyProps
    extends HTMLAttributes<HTMLDivElement> {
    propertyKey: string;
    propertyDescription: SinglePropertyDescription;
    editable?: boolean;
}

function ViewSingleProperty({
                                propertyKey,
                                propertyDescription,
                                editable,
                                ...props
                            }: ViewSinglePropertyProps) {
    const {subsumptionResult, overwriteValue} = useSubsumptionResult();
    const [value, setValue] = useState<ChatGPTResponseValueType>(
        subsumptionResult.getValue(propertyKey)
    );
    const [chatGPTValue, setChatGPTValue] = useState<ChatGPTResponseValueType>(
        subsumptionResult.getChatGPTValue(propertyKey)
    );
    const [showExamples, setShowExamples] = useState<boolean>(true);
    const isEditable = editable ?? false;
    // helper values to clean up later code
    const hasDescription = propertyDescription.shortDescription !== undefined;
    // make short description to array if it is just a string
    const shortDescriptionArray =
        typeof propertyDescription.shortDescription === "string"
            ? [propertyDescription.shortDescription]
            : propertyDescription.shortDescription;
    const hasPositiveExamples =
        propertyDescription.positiveExamples !== undefined;
    const hasNegativeExamples =
        propertyDescription.negativeExamples !== undefined;
    const hasExamples = hasPositiveExamples || hasNegativeExamples;

    useEffect(() => {
        setValue(subsumptionResult.getValue(propertyKey));
        setChatGPTValue(subsumptionResult.getChatGPTValue(propertyKey));
    }, [subsumptionResult, propertyKey]);

    const handleUpdateLogicValue = (newLogicValue: LogicValue) => {
        overwriteValue(propertyKey, newLogicValue);
    };


    /*******************************************************************************
     ******************** Do not show this property if hidden **********************
     ********************************************************************************/
    if (subsumptionResult.isHidden(propertyKey)) {
        return <div></div>
    }

    /*******************************************************************************
     ******************** Set the value depending on current type ******************
     ********************************************************************************/
    let displayName = propertyDescription.displayName;
    // the state defines how the display name will be shown. Set to true/false to color green/red
    let state: LogicValue = "unknown";
    let valueComponent = <></>;
    let additionalClassName = "";
    switch (propertyDescription.type) {
        case "string":
            valueComponent = (
                <Form.Control
                    type={"text"}
                    defaultValue={value}
                    readOnly={true}
                    disabled={true}
                />
            );
            break;
        case "number":
            // unfortunately, we have to hard code the case of the offendersAge :(((((((((
            // We do not show the direct age, only if the possible offender is criminal responsible according to the
            // german law.
            // Note, that in Germany, everybody that is 14 years or older is criminal responsible (in german: strafmündig)
            if (propertyKey === "offendersAge") {
                let transformedValue = ageToCriminalResponsibility(value as number);
                let transformedChatGPTValue = ageToCriminalResponsibility(
                    chatGPTValue as number
                );
                valueComponent = (
                    <ViewLogicValue
                        value={transformedValue}
                        chatGPTValue={transformedChatGPTValue}
                        onValueUpdate={(newValue) => {
                            overwriteValue(
                                propertyKey,
                                criminalResponsibilityToAge(newValue, chatGPTValue as number)
                            );
                        }}
                        editable={isEditable}
                    />
                );
                displayName = OFFENDERS_AGE_DISPLAY_NAME;
                state = transformedValue;
                break;
            }
            valueComponent = <Form.Control type={"number"} defaultValue={value}/>;
            break;
        case "LogicValue":
            valueComponent = (
                <ViewLogicValue
                    value={value as LogicValue}
                    chatGPTValue={chatGPTValue as LogicValue}
                    onValueUpdate={handleUpdateLogicValue}
                    editable={isEditable}
                />
            );
            state = value as LogicValue;
            break;
    }

    const exampleTypes: string[] = [];
    if (hasPositiveExamples) {
        exampleTypes.push("positive");
    }
    if (hasNegativeExamples) {
        exampleTypes.push("negative");
    }
    let exampleTypesText = exampleTypes.join(" und ");
    //capitalize example text
    exampleTypesText =
        exampleTypesText.charAt(0).toUpperCase() + exampleTypesText.slice(1);

    const showExamplesButton = (
        <div className={"show-examples-button"}>
            <Button variant={"link"} onClick={() => setShowExamples(!showExamples)}>
                {showExamples ? (
                    <span>
            <span>{exampleTypesText} Beispiele ausblenden</span>
            <span>
              <FaAngleUp/>
            </span>
          </span>
                ) : (
                    <span>
            <span>{exampleTypesText} Beispiele anzeigen</span>
            <span>
              <FaAngleDown/>
            </span>
          </span>
                )}
            </Button>
        </div>
    );

    return (
        <div {...props} className={"ChatGPTSingleResult"}>
            <div
                className={`name ${
                    hasExamples ? "clickable" : ""
                } ${additionalClassName} ${state}`}
                onClick={() => setShowExamples(!showExamples)}
            >
                <div className={`display-name`}>{displayName}</div>
                {hasExamples && <FaQuestionCircle/>}
                {propertyDescription.advanced && (
                    <Badge bg="secondary">Erweitert</Badge>
                )}
            </div>
            {hasDescription && (
                <ExplanationList
                    className={"description"}
                    explanations={shortDescriptionArray ?? []}
                />
            )}
            {showExamples && hasPositiveExamples && (
                <ExampleList
                    className={"positive-examples"}
                    type={"positive"}
                    examples={propertyDescription.positiveExamples ?? []}
                />
            )}
            {hasExamples && showExamplesButton}
            {showExamples && hasNegativeExamples && (
                <ExampleList
                    className={"negative-examples"}
                    type={"negative"}
                    examples={propertyDescription.negativeExamples ?? []}
                />
            )}
            <div className={"value"}>{valueComponent}</div>
        </div>
    );
}

export default ViewSingleProperty;
