import {JuriCoreStatus, JuriNode, JuriNodeValues} from "./juricore";

export enum JunBotQuestionTypes {
    YesNo = "YesNo",
    MultipleChoice = "MultipleChoice"
}

export type JunBotQuestionValues = {
    status: JuriCoreStatus,
    show: boolean,
    question: string,
    name: string,
    type?: JunBotQuestionTypes,
    description?: string,
    textAnswer?: string,
    textAnswerYes?: string,
    textAnswerNo?: string,
    defaultShowDescription?: boolean
}
export const DEFAULT_QUESTION_TYPE = JunBotQuestionTypes.YesNo

export interface JunBotQuestion extends JuriNode<JunBotQuestionValues> {
}

export const STRING_LIST_SEPARATOR = ";"
export type JunBotResponseOptionValues = {
    status: JuriCoreStatus,
    show: boolean,
    description?: string,
    positiveExamples?: string,
    negativeExamples?: string,
    name: string
}

export interface JunBotResponseOption extends JuriNode<JunBotResponseOptionValues> {
}

export type JunBotResultValues = {
    status: JuriCoreStatus,
    show: boolean,
    answer: string,
    priority: number
}
export const DEFAULT_PRIORITY = 0

export interface JunBotResult extends JuriNode<JunBotResultValues> {
}


// ================================================================
// ================================================================
// ================= Type Casts ===================================
// ================================================================
/**
 * This function simply checks if a given general JuriNode contains all the values that are passed to this function.
 * Use this function to check whether a node e.g. has a status property.
 *
 * @param node the raw JuriNode that should be checked.
 * @param requiredNodeValues list of value keys that must be contained in the values of the passed node
 */
function requireNodeValues(node: JuriNode<JuriNodeValues>, requiredNodeValues: string[]) {
    const missingValues: string[] = []

    requiredNodeValues.forEach(requiredValue => {
        if (!node.values.hasOwnProperty(requiredValue)) {
            missingValues.push(requiredValue)
        }
    })

    if (missingValues.length > 0) {
        throw new Error(`Node '${node._id} is missing following values: ${missingValues.join(', ')}`)
    }
}

export const parseNodeToQuestion = (rawNode: JuriNode<JuriNodeValues>): JunBotQuestion => {
    const REQUIRED_VALUES = ['status', 'show', 'question', 'name']
    requireNodeValues(rawNode, REQUIRED_VALUES)

    rawNode.values.type = rawNode.values.type ?? DEFAULT_QUESTION_TYPE

    return rawNode as JunBotQuestion
}

export const parseNodeToResponseOption = (rawNode: JuriNode<JuriNodeValues>): JunBotResponseOption => {
    const REQUIRED_VALUES = ["status", "show", "name"]
    requireNodeValues(rawNode, REQUIRED_VALUES)
    return rawNode as JunBotResponseOption
}

export const parseNodeToResult = (rawNode: JuriNode<JuriNodeValues>): JunBotResult => {
    rawNode.values.priority = rawNode.values.priority ?? DEFAULT_PRIORITY

    const REQUIRED_VALUES = ['status', 'show', 'answer', 'priority']
    requireNodeValues(rawNode, REQUIRED_VALUES)

    return (rawNode as unknown) as JunBotResult
}