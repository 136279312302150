import React, {useRef, useState} from 'react';
import './App.scss';
import {APIContext} from "./context/APIContext";
import {Outlet} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import SSOProtected from "./components/auth/SSOProtected/SSOProtected";
import Header from "./components/Header/Header";
import {Settings} from "./context/Settings";
import Footer from "./components/Footer/Footer";
import {LogicValue} from "./types/swishAPI";
import DisclaimerPopUp from "./components/popups/DisclaimerPopUp/DisclaimerPopUp";
import {useDimensions} from "./hooks/useDimensions";
import {IsMobile} from "./context/IsMobile";

function App() {
    const [apiUUID, setApiUUID] = useState<string>("")
    const [expertMode, setExpertMode] = useState<LogicValue>("unknown")
    const mainApp = useRef<HTMLDivElement>(null);
    const windowDimensions = useDimensions()
    const MOBILE_THRESHOLD_WIDTH = 800;
    const isMobile = windowDimensions.width < MOBILE_THRESHOLD_WIDTH;

    let mainContent = <Outlet/>

    return (
        <APIContext.Provider value={{
            uuid: apiUUID,
            setUUID: setApiUUID
        }}>
            <Settings.Provider value={{
                expertMode,
                setExpertMode
            }}>
                <IsMobile.Provider value={isMobile}>
                    <CookiesProvider>
                        <div className="App" ref={mainApp}>
                            <Header/>
                            <div className={'Main'}>
                                <SSOProtected loginRequired={process.env.REACT_APP_AUTH_REQUIRED !== 'false'}>
                                    <DisclaimerPopUp/>
                                    {mainContent}
                                </SSOProtected>
                            </div>
                            <Footer className={'Footer'}/>
                        </div>
                    </CookiesProvider>
                </IsMobile.Provider>
            </Settings.Provider>
        </APIContext.Provider>
    );
}

export default App;
