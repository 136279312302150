import {ChatGPTResponse} from "../types/swishAPI";
import {ChatGPTResponseOverwrite, ChatGPTResponseValueType, SubsumptionResultView} from "../context/ChatGPTResponseCTX";
import _ from "lodash";
import {DEFAULT_CHATGPT_RESPONSE} from "../defaultValues/defaultChatGPTResponse";

export class SubsumptionResult implements SubsumptionResultView {
    private _rawResponse: ChatGPTResponse;
    private _overwrites: ChatGPTResponseOverwrite;
    private _isLoading: boolean;
    private _hasUpdatedLLMResponse: boolean;
    private _hiddenKeyPaths: string[];

    constructor(apiResponse: ChatGPTResponse | undefined) {
        this._rawResponse = apiResponse ?? DEFAULT_CHATGPT_RESPONSE
        this._overwrites = {}
        this._isLoading = false
        this._hasUpdatedLLMResponse = false
        this._hiddenKeyPaths = []
    }

    /**
     * This function overwrites the raw response at a specific key.
     * {@see getValue} will use registered overwrites.
     * However, {@see getChatGPTValue} will always return the value of the raw response
     *
     * For the key path, we use the dot notation, i.e., to refer the key 'x' in the following nested object, we write the
     * path 'a.b[0].x'.
     *
     * {a: {b: [{x: '...'}]},
     *  c: ...
     *  ...}
     *
     *  Note that this is the notation used by lodash methods _.get and _.at
     *
     * @param keyPath the path of the key for the requested value. Use dot notation to refer to nested objects.
     * @param value the new value for the specified key path
     */
    overwriteValue(keyPath: string, value: ChatGPTResponseValueType) {
        this._overwrites[keyPath] = value
    }

    /**
     * This function returns the current value in the ChatGPT response at a specific key path.
     * It respects the current overwrite object, i.e., values that overwrite the corresponding values in the response object.
     *
     * For the key path, we use the dot notation, i.e., to refer the key 'x' in the following nested object, we write the
     * path 'a.b[0].x'.
     *
     * {a: {b: [{x: '...'}]},
     *  c: ...
     *  ...}
     *
     *  Note that this is the notation used by lodash methods _.get and _.at
     *
     * @param keyPath the path of the key for the requested value. Use dot notation to refer to nested objects.
     */
    getValue(keyPath: string): ChatGPTResponseValueType {
        // TODO: handle the getting of array values:
        //      1. extend return type with some array type (maybe any[]?)
        //      2. check after _.get if it is an array
        //      3. Maybe use overwrites for this key to overwrite the array length (?) => update parameter type of overwriteValue
        //      4. Iterate over each element and apply the overwrites (at `${keyPath}[${i]]`)

        if (this.overwrites.hasOwnProperty(keyPath)) {
            return this.overwrites[keyPath]
        }
        return _.get(this.rawResponse, keyPath)
    }

    /**
     * same as {@see getValue}, but without applying the overwrites.
     * @param keyPath the path of the key for the requested value. Use dot notation to refer to nested objects.
     */
    getChatGPTValue(keyPath: string): ChatGPTResponseValueType {
        return _.get(this.rawResponse, keyPath)
    }

    /**
     * This function applies the registered overwrites to the current ChatGPT response.
     *
     * @return a ChatGPT response object where the overwrites are applied.
     */
    applyOverwrites(): ChatGPTResponse {
        let newResponse = _.cloneDeep(this.rawResponse)
        _.forOwn(this.overwrites, (value, key) => {
            _.set(newResponse, key, value)
        })

        return newResponse
    }

    setLLMResponse(llmResponse: ChatGPTResponse) {
        this.rawResponse = llmResponse
        this._hasUpdatedLLMResponse = true
    }

    /**
     * Whether the underlying llm response was updated at least once, i.e., the function {@see setLLMResponse} was
     * called at least once.
     */
    hasUpdatedLLMResponse(): boolean {
        return this._hasUpdatedLLMResponse
    }

    isHidden(keyPath: string) {
        return this.hiddenKeyPaths.includes(keyPath)
    }

    clone(): SubsumptionResult {
        let copy = new SubsumptionResult(this.rawResponse)
        copy._isLoading = this.isLoading
        copy._overwrites = _.cloneDeep(this.overwrites)
        copy._hasUpdatedLLMResponse = this._hasUpdatedLLMResponse
        return copy
    }

    /*********************************************************
     ************** Getter and Setter ************************
     *********************************************************/
    get rawResponse(): ChatGPTResponse {
        return this._rawResponse;
    }

    set rawResponse(value: ChatGPTResponse) {
        this._rawResponse = value;
    }

    get overwrites(): ChatGPTResponseOverwrite {
        return this._overwrites;
    }

    set overwrites(value: ChatGPTResponseOverwrite) {
        this._overwrites = value;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    set isLoading(value: boolean) {
        this._isLoading = value;
    }


    get hiddenKeyPaths(): string[] {
        return this._hiddenKeyPaths;
    }

    set hiddenKeyPaths(value: string[]) {
        this._hiddenKeyPaths = value;
    }
}