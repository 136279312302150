import "./ExpandableText.css"
import React, {HTMLAttributes, useState} from 'react';
import {FaAngleDown, FaAngleRight} from "react-icons/fa6";

interface ExpandableTextProps extends HTMLAttributes<HTMLDivElement> {
    titleElement: React.ReactNode | ((isExpanded: boolean) => React.ReactNode)
    defaultExpanded?: boolean
}

function ExpandableText({titleElement, defaultExpanded, children, ...props}: ExpandableTextProps) {
    const className = 'ExpandableText ' + (props.className ?? '')
    const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded ?? false)

    return <div {...props} className={className}>
        <div className={'ExpandableText-title-button clickable'} onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <FaAngleDown/> : <FaAngleRight/>}
        </div>
        <div className={'ExpandableText-title clickable'} onClick={() => setIsExpanded(!isExpanded)}>
            {
                typeof titleElement === 'function' && titleElement(isExpanded)
            }
            {
                typeof titleElement !== 'function' && titleElement
            }
        </div>

        <div className={`ExpandableText-content ${isExpanded ? 'showing' : 'hidden'}`}>
            {children}
        </div>
    </div>
}

export default ExpandableText;