import "./IntersectionalBot.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {NodeShowState, useIntersectionalBot} from "../../hooks/juriCoreHooks/useIntersectionalBot";
import {JunBotResult, JunBotQuestion} from "../../types/junbot";
import {IntersectionalBotCTX} from "../../context/IntersectionalBotCTX";
import {Button} from "react-bootstrap";
import {JuriNodeId} from "../../types/juricore";
import ViewMessage from "./messages/ViewMessage";
import IntersectionalBotPopup from "./Popup/IntersectionalBotPopup";

export enum MessageType {
    Question = 'question',
    Answer = 'answer',
    Result = 'result'
}

export interface GeneralMessage {
    msgType: MessageType,
    id: string
}

export interface QuestionMessage extends GeneralMessage {
    msgType: MessageType.Question
    question: JunBotQuestion
}

export interface AnswerMessage extends GeneralMessage {
    msgType: MessageType.Answer
    answer: React.ReactNode;
}

export interface ResultMessage extends GeneralMessage {
    msgType: MessageType.Result,
    results: JunBotResult[]
}

export type Message = QuestionMessage | AnswerMessage | ResultMessage;

interface IntersectionalBotProps extends HTMLAttributes<HTMLDivElement> {
    botRef?: React.RefObject<HTMLDivElement>;
}

function IntersectionalBot({botRef, ...props}: IntersectionalBotProps) {
    const className = 'IntersectionalBot ' + (props.className ?? '')
    const [lastUpdate, setLastUpdate] = useState<number | undefined>(undefined)
    const backend = useIntersectionalBot(() => setLastUpdate(Date.now))
    const [messageLog, setMessageLog] = useState<Message[]>([])
    const [askedQuestionIds, setAskedQuestionIds] = useState<JuriNodeId[]>([])
    const [shownResultIds, setShownResultIds] = useState<JuriNodeId[]>([])


    // this effect is called whenever a user provided an answer to a question
    useEffect(() => {
        if (lastUpdate === undefined) {
            // reset backend on first render
            backend.reset()
            return
        }

        const abortController = new AbortController()

        // query new questions
        backend.getQuestions(undefined, NodeShowState.ONLY_SHOWN, abortController.signal)
            .then(questions => {
                const newQuestions = questions.filter(question => !askedQuestionIds.includes(question._id))
                const newAskedQuestionIds = newQuestions.map(question => question._id)
                const newQuestionMessages = newQuestions.map(generateQuestionMessage)

                setMessageLog((oldMessageLog) => [...oldMessageLog, ...newQuestionMessages])
                setAskedQuestionIds((oldAskedQuestions) => [...oldAskedQuestions, ...newAskedQuestionIds]);
            })
            .catch(reason => {
                if (reason.code === "ERR_CANCELED") {
                    // do nothing as the cancellation is expected
                    return
                }
                // todo: good error handling
                throw reason
            })

        // query new results
        backend.getResults(1, NodeShowState.ONLY_SHOWN, abortController.signal)
            .then(results => {
                if (results.length === 0) {
                    return; // no results found
                }

                const newResults = results.filter(result => !shownResultIds.includes(result._id))
                const newResultIds = newResults.map(result => result._id)

                setShownResultIds(currentShownResultIds => [...currentShownResultIds, ...newResultIds])
                // add result message that bundles all new results to message log
                setMessageLog(oldMsgLog => [...oldMsgLog, generateResultMessage(newResults)])
            })
            .catch(reason => {
                if (reason.code === "ERR_CANCELED") {
                    // do nothing as the cancellation is expected
                    return
                }
                // todo: good error handling
                throw reason
            })

        return () => abortController.abort()
    }, [lastUpdate])

    const handleReset = () => {
        backend.reset()
        setMessageLog([])
        setAskedQuestionIds([])
        setShownResultIds([])
    }

    const handleCreateAnswer = (answer: AnswerMessage) => {
        setMessageLog((currentMessageLog) => [...currentMessageLog, answer])
    }

    const handleClickGoToBot = () => {
        botRef?.current?.scrollIntoView()
    }

    return (
        <div {...props} className={className} ref={botRef}>
            <IntersectionalBotPopup onGoToBot={handleClickGoToBot}/>
            <IntersectionalBotCTX.Provider value={backend}>
                {
                    messageLog.map(message => <ViewMessage key={message.id}
                                                           message={message}
                                                           onCreateAnswer={handleCreateAnswer}
                    />)
                }
            </IntersectionalBotCTX.Provider>
            <Button onClick={handleReset} variant={'outline-secondary'}>
                Zurücksetzen
            </Button>
        </div>
    )
}


function generateQuestionMessage(question: JunBotQuestion): QuestionMessage {
    return {
        msgType: MessageType.Question,
        id: question._id,
        question
    }
}

function generateResultMessage(results: JunBotResult[]): ResultMessage {
    return {
        msgType: MessageType.Result,
        id: results.map(result => result._id).join('-'),
        results: results
    }
}

export default IntersectionalBot;