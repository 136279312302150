import "./Twitter.scss"
import React, {HTMLAttributes, useEffect, useState} from 'react';
import Progress from "../Progress/Progress";
import {Outlet, useOutletContext} from "react-router-dom";
import {HatespeechDataCTX} from "../../context/HatespeechDataCTX";
import {SubsumptionResult} from "../../classes/SubsumptionResult";
import {ChatGPTResponseValueType} from "../../context/ChatGPTResponseCTX";
import {ChatGPTResponse, SwishAPIResponseJSON} from "../../types/swishAPI";
import {DEFAULT_CHATGPT_RESPONSE} from "../../defaultValues/defaultChatGPTResponse";
import {useNavigateMaintainSearchParams} from "../../hooks/useNavigateMaintainSearchParams";
import {routes} from "../../routing/router";
import {FactDescriptionInput} from "../../types/types";
import {FaBook, FaCircleCheck, FaRobot} from "react-icons/fa6";
import {useDimensions} from "../../hooks/useDimensions";
import {useAPIConnector} from "../../hooks/useAPIConnector";

export enum TwitterSteps {
    FETCH_TWEET,
    SUBSUME,
    QUERY_SWISH,
    VIEW_RESULT
}

type SetStepType = (newStep: TwitterSteps) => void
type StepProgressionType = (newStepProgress: number) => void

interface ProgressionHandlingType {
    setCurProgression: StepProgressionType,
    setStep: SetStepType
}

interface CheckTweetProps extends HTMLAttributes<HTMLDivElement> {

}

/**
 * This component is the Twitter module of the IsThisHatespeech frontend.
 * It contains an Input for a twitter link, a tweet preview (with context) and the buttons/logic for checking a tweet.
 *
 * @param props
 * @constructor
 */
function Twitter({...props}: CheckTweetProps) {
    const navigate = useNavigateMaintainSearchParams();
    const windowDimensions = useDimensions();

    // data
    const [factDescription, setFactDescription] = useState<FactDescriptionInput | null>(null)
    const [subsumptionResult, setSubsumptionResult] = useState<SubsumptionResult>(new SubsumptionResult(DEFAULT_CHATGPT_RESPONSE))
    const [evaluationResult, setEvaluationResult] = useState<SwishAPIResponseJSON | null>(null)

    // handle progression/steps, i.e., navigation
    const [curProgression, setCurProgression] = useState<number>(0)
    const [curStep, setCurStep] = useState<number>(0)

    const api = useAPIConnector()

    const stepTitles = [
        "Auswahl des zu prüfenden Tweets",
        "Einschätzung durch ChatGPT",
        "Rechtliche Bewertung der Einschätzung von ChatGPT",
        "Ergebnis"
    ]

    useEffect(() => {
        // always reset the progression if a new step is reached.
        setCurProgression(0)

        // check that this step is actually allowed, i.e., all necessary data is already given
        switch (curStep) {
            case TwitterSteps.FETCH_TWEET:
                navigate(routes.twitter.input)
                break
            case TwitterSteps.SUBSUME:
                if (factDescription === null) {
                    setCurStep(TwitterSteps.FETCH_TWEET)
                    break;
                }
                navigate(routes.twitter.subsume)
                break;
            case TwitterSteps.QUERY_SWISH:
                if (!subsumptionResult.hasUpdatedLLMResponse()) {
                    setCurStep(TwitterSteps.QUERY_SWISH)
                    break;
                }
                navigate(routes.twitter.querySwish)
                break;
            case TwitterSteps.VIEW_RESULT:
                if (evaluationResult === null) {
                    setCurStep(TwitterSteps.QUERY_SWISH)
                }
                navigate(routes.twitter.result)
                break;
        }
    }, [curStep])

    const handleGoToStep = (newStep: TwitterSteps) => {
        setCurStep(newStep)
    }

    const handleOverwriteSubsumptionResult = (keyPath: string, value: ChatGPTResponseValueType) => {
        let newSubsumptionResult = subsumptionResult.clone()
        newSubsumptionResult.overwriteValue(keyPath, value)
        // first set new subsumption result without querying for new hidden values
        // this increases responsiveness after clicking the buttons.
        setSubsumptionResult(newSubsumptionResult)

        // now we query the hidden endpoint to find the new hidden values after the change
        // TODO: cancel previous request to avoid race conditions when changing values fast.
        let newSubsumptionResultWithHidden = newSubsumptionResult.clone()
        const overwrittenResult = newSubsumptionResultWithHidden.applyOverwrites()
        api.hideNonSensical(overwrittenResult).then((hiddenKeyPaths) => {
            newSubsumptionResultWithHidden.hiddenKeyPaths = hiddenKeyPaths
            setSubsumptionResult(newSubsumptionResultWithHidden)
        })
    }

    const handleSetLLMResponse = (llmResponse: ChatGPTResponse) => {
        let newSubsumptionResult = subsumptionResult.clone()
        newSubsumptionResult.setLLMResponse(llmResponse)
        // Note: here we only set the new subsumption result after querying the hidden values.
        // setting the llm response is anyways after an api call where the user does not know how long it takes.
        // TODO: cancel previous request to avoid race conditions when changing values fast.
        const overwrittenResult = newSubsumptionResult.applyOverwrites()
        api.hideNonSensical(overwrittenResult).then((hiddenKeyPaths) => {
            newSubsumptionResult.hiddenKeyPaths = hiddenKeyPaths
            setSubsumptionResult(newSubsumptionResult)
        })
    }

    const handleSetIsLoading = (isLoading: boolean) => {
        let newSubsumptionResult = subsumptionResult.clone()
        newSubsumptionResult.isLoading = isLoading
        setSubsumptionResult(newSubsumptionResult)
    }

    return (
        <HatespeechDataCTX.Provider value={{
            input: {
                data: factDescription,
                setInput: setFactDescription
            },
            subsumption: {
                result: subsumptionResult,
                setLLMResult: handleSetLLMResponse,
                overwriteSubsumptionValue: handleOverwriteSubsumptionResult,
                setIsLoading: handleSetIsLoading,
            },
            evaluate: {
                result: evaluationResult,
                setResult: setEvaluationResult
            }
        }}>
            <div {...props}>
                <Progress numSteps={stepTitles.length}
                          curStep={curStep}
                          curProgress={curProgression}
                          orientation={'horizontal'}
                          className={'Main-progress'}
                          onClickStep={handleGoToStep}
                          stepNames={{
                              1: <FaRobot aria-label={'Roboter'}/>,
                              2: <FaBook aria-label={'Buch'}/>,
                              3: <FaCircleCheck aria-label={'Haken'}/>,
                          }}
                          stepDescriptions={{
                              1: "ChatGPT",
                              2: "Wissensdatenbank",
                              3: "Ergebnis",
                          }}
                          descriptionMode={windowDimensions.width < 700 ? 'only-active' : 'all'}
                />
                <div className={'Main-content'}>
                    <Outlet context={{
                        setStep: handleGoToStep,
                        setCurProgression: setCurProgression
                    } satisfies ProgressionHandlingType}/>
                </div>
            </div>
        </HatespeechDataCTX.Provider>
    )
}


export function useProgressionHandling() {
    return useOutletContext<ProgressionHandlingType>()
}

export default Twitter;