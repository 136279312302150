import "./MultipleChoiceQuestion.scss"
import React, {FormEventHandler, ReactNode, useContext, useEffect, useState} from 'react';
import {IntersectionalBotCTX} from "../../../../../context/IntersectionalBotCTX";
import ResponseOption from "./ResponseOption/ResponseOption";
import {Form, Placeholder} from "react-bootstrap";
import LoadingButton from "../../../../LoadingButton/LoadingButton";
import {QuestionProps} from "../Question";
import {MessageType} from "../../../IntersectionalBot";
import {JuriNodeId} from "../../../../../types/juricore";
import {JunBotResponseOption} from "../../../../../types/junbot";
import ExpandableText from "../../../../ExpandableText/ExpandableText";
import JunBotMarkdown from "../../../../JunBotMarkdown/JunBotMarkdown";

interface MultipleChoiceQuestionProps extends QuestionProps {
    onUpdateLoadingState?: (newIsLoading: boolean) => void;
}

function MultipleChoiceQuestion({question, ...props}: MultipleChoiceQuestionProps) {
    const className = 'MultipleChoiceQuestion ' + (props.className ?? '')
    const intersectionalBot = useContext(IntersectionalBotCTX)
    const [responseOptionIds, setResponseOptionIds] = useState<string[]>([])
    const [responseOptions, setResponseOptions] = useState<Record<JuriNodeId, JunBotResponseOption>>({})

    // we separate all response options in three groups:
    //  - shown: values.show === true
    //  - hidden: values.show === false -> these response options are hidden under the show more button
    //  - loading: all the response options that are still loading
    const [shownResponseOptions, setShownResponseOptions] = useState<ReactNode[]>([])
    const [hiddenResponseOptions, setHiddenResponseOptions] = useState<ReactNode[]>([])
    const [loadingResponseOptions, setLoadingResponseOptions] = useState<ReactNode[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isAnswered, setIsAnswered] = useState<boolean>(false)
    const [isInvalid, setIsInvalid] = useState<boolean>(false)

    useEffect(() => {
        intersectionalBot.getResponseOptionIds(question).then(setResponseOptionIds)
    }, [question])

    useEffect(() => {
        // query the actual response options
        responseOptionIds?.forEach(responseOptionId => {
            intersectionalBot.getResponseOption(responseOptionId).then(responseOption => {
                setResponseOptions(currentResponseOptions => ({
                    ...currentResponseOptions,
                    [responseOptionId]: responseOption
                }))
            })
        })
    }, [responseOptionIds]);

    useEffect(() => {
        if (props.onUpdateLoadingState) {
            props.onUpdateLoadingState(isLoading)
        }
    }, [isLoading]);

    // ================================ Create the different response option groups ==============================
    useEffect(() => {
        const newShownResponseOptions = responseOptionIds
            .filter(id => responseOptions.hasOwnProperty(id) && responseOptions[id].values.show)
            .map(id => <ResponseOption key={id} responseOption={responseOptions[id]}/>)
        setShownResponseOptions(newShownResponseOptions)

        const newHiddenResponseOptions = responseOptionIds
            .filter(id => responseOptions.hasOwnProperty(id) && !responseOptions[id].values.show)
            .map(id => <ResponseOption key={id} responseOption={responseOptions[id]}/>);
        setHiddenResponseOptions(newHiddenResponseOptions)

        const newLoadingResponseOptions = responseOptionIds
            .filter(id => !responseOptions.hasOwnProperty(id))
            .map(id => <ResponseOption key={id} responseOption={responseOptions[id]}/>);
        setLoadingResponseOptions(newLoadingResponseOptions)

    }, [responseOptions, responseOptionIds]);


    // ======================= Placeholder before any response option is loaded ===============================
    if (responseOptionIds.length === 0) {
        return <div {...props} className={className}>
            <h3>{question.values.name}</h3>
            <Placeholder animation={'glow'}/>
        </div>
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        const formElements = event.currentTarget.elements;
        const checkedResponseOptionIds: string[] = [];

        responseOptionIds.forEach(responseOptionId => {
            const checkbox = formElements.namedItem(responseOptionId) as HTMLInputElement;
            if (checkbox && checkbox.checked) {
                checkedResponseOptionIds.push(responseOptionId);
            }
        });

        if (checkedResponseOptionIds.length === 0) {
            setIsInvalid(true)
            return
        }
        setIsInvalid(false)

        setIsLoading(true)
        setIsAnswered(true)
        intersectionalBot.answerMultipleChoiceQuestion(question, responseOptionIds, checkedResponseOptionIds)
            .then(() => setIsLoading(false))

        // generate answer
        if (props.onCreateAnswer) {
            const titleText = question.values.textAnswer ? question.values.textAnswer + "\n\n" : '';

            const answerText = checkedResponseOptionIds
                .map(id => `* ${responseOptions[id].values.name}`)
                .join('\n')

            props.onCreateAnswer({
                msgType: MessageType.Answer,
                id: question._id + "-answer-" + Date.now(),
                answer: <JunBotMarkdown>{titleText + answerText}</JunBotMarkdown>
            })
        }
    }

    return <div {...props} className={className}>
        <JunBotMarkdown className={'MultipleChoiceQuestion-question'}>
            {question.values.question}
        </JunBotMarkdown>
        {
            !isAnswered && <Form onSubmit={handleSubmit} className={'MultipleChoiceQuestion-Form'}>
                <div className={'MultipleChoiceQuestion-ResponseOptions'}>
                    {shownResponseOptions}
                    {loadingResponseOptions.length > 0 && <ResponseOption responseOption={undefined}/>}
                    {
                        hiddenResponseOptions.length > 0 && <ExpandableText titleElement={"mehr anzeigen"}>
                            {hiddenResponseOptions}
                        </ExpandableText>
                    }
                </div>
                {
                    isInvalid && <Form.Label className={'MultipleChoiceQuestion-feedback'}>
                    Bitte wähle mindestens eine Option aus.
                    </Form.Label>
                }
                <LoadingButton isLoading={isLoading}
                               type={'submit'}
                               className={'MultipleChoiceQuestion-Submit'}>
                    Bestätigen
                </LoadingButton>
            </Form>
        }
    </div>
}

export default MultipleChoiceQuestion;