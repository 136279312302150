import "./DisclaimerPopUp.css"
import React, {HTMLAttributes} from 'react';
import PopUp from "../PopUp";
import {FaSection} from "react-icons/fa6";

interface DisclaimerPopUpProps extends HTMLAttributes<HTMLDialogElement> {
}

function DisclaimerPopUp({...props}: DisclaimerPopUpProps) {
    const className = 'DisclaimerPopUp ' + (props.className ?? '')

    const header = <span>
        <FaSection aria-label={"Disclaimer Überschrift Paragraphzeichen"}/>
    </span>

    return <PopUp header={header}
                  closeButton={'Verstanden'}
                  className={className}
    >
        <h1>Mit Künstlicher Intelligenz in den Kampf gegen Hatespeech!</h1>
        <p>
            <strong>Hinweis: </strong>
            Die von dir bereitgestellten Daten werden zur Verarbeitung, in Form der Sachverhaltserfassung, an ChatGPT
            weitergeleitet. Wir bitten Dich insofern, keine personenbezogenen Daten insbesondere die Dritter,
            einzugeben. Mit dem "Verstanden"-Button stimmst Du dem Hinweis zu.
        </p>
        <p>
            isthishatespeech kann und soll keine Rechtsberatung ersetzen.
        </p>
    </PopUp>
}

export default DisclaimerPopUp;