import "./ViewPropertyGroup.scss"
import React, {HTMLAttributes, ReactElement, useEffect, useState} from 'react';
import {ChatGPTResponsePropertyDescription, GroupPropertyDescription} from "../../../../../types/swishAPI";
import ViewChatGPTResult from "../ViewChatGPTResult";
import _ from "lodash";
import ExpandButton from "../../../../ExpandButton/ExpandButton";
import {useSubsumptionResult} from "../../../../../hooks/twitterDataHooks/useSubsumptionResult";

const DEFAULT_EXPANDED_GROUPS = ["victim"];

interface ViewPropertyGroupProps extends HTMLAttributes<HTMLDivElement> {
    groupKey?: string,
    propertyDescription: GroupPropertyDescription,
    showAdvanced: boolean,
    editable?: boolean
}

/**
 * This functions creates a full path parameter key for a child element.
 * It creates the following key:
 *  {groupKey}.{childKey}
 *
 *  Use these keys as keys for the children. Otherwise, they can not query the subsumption result object.
 * @param groupKey the key of the current group. If the group key is undefined, this means that the child is a
 *      root child => its local key is the global key.
 * @param childKey the local key of the respective child of a group
 * @return full global child key. Uniquely identifies the child among all elements.
 */
function createFullChildKey(groupKey: string | undefined, childKey: string): string {
    return groupKey !== undefined ? `${groupKey}.${childKey}` : childKey
}

/**
 * This component visualizes a group of properties as specified by the GroupPropertyDescription type.
 * It uses ViewChatGPTResult to draw each one of the properties.
 *
 * The displayName and description is used as heading/description for this group.
 * If the displayName is the empty string (''), no heading is drawn. Not even the description.
 *
 * @param groupKey the key of this group. If it is not undefined, the keys of each child property is prefixed with it.
 * @param propertyDescription the description of this group (including the description of its children)
 * @param showAdvanced whether the advanced properties should be shown
 * @param editable whether the values in this group should be editable (default: false)
 * @param props other properties that are directly passed ot the enclosing div
 * @constructor
 */
function ViewPropertyGroup({
                               groupKey,
                               propertyDescription,
                               showAdvanced,
                               editable,
                               ...props
                           }: ViewPropertyGroupProps) {
    const [displayedChildren, setDisplayedChildren] = useState<ChatGPTResponsePropertyDescription>({})
    const showHeader = propertyDescription.description !== undefined
    const [expanded, setExpanded] = useState<boolean>(DEFAULT_EXPANDED_GROUPS.includes(groupKey ?? '') || !showHeader)
    const {subsumptionResult} = useSubsumptionResult()
    const isEditable = editable ?? false

    useEffect(() => {
        setDisplayedChildren(_.pickBy(propertyDescription.children, (childDescription, childKey) => {
            const fullKey = createFullChildKey(groupKey, childKey)
            if (subsumptionResult.isHidden(fullKey)) return false;
            return showAdvanced || !childDescription.advanced
        }))
    }, [propertyDescription.children, showAdvanced, subsumptionResult])

    if (Object.keys(displayedChildren).length === 0) {
        return <></>
    }

    // skip header if the display name is empty (used by ViewPropertyArray and ChatGPTResult)
    const showHeading = propertyDescription.displayName !== ''
    const indentProperties = propertyDescription.displayName !== ''

    // build body according to the expanded state
    let body: ReactElement;
    // transform the displayed children in entries to iterate over them and count the children
    const childrenEntries = Object.entries(displayedChildren)
    if (expanded) {
        body = <div className={`properties`}>
            {
                childrenEntries.map(([childKey, childDescription], i) => {
                    // calculate whether a separator should be displayed underneath this child property
                    const showSeparator = childDescription.type !== 'Group' // groups are indented => no additional separator needed
                        && childDescription.type !== 'Array' // same for arrays
                        && i < childrenEntries.length - 1 // last element of group has should not have a separator

                    // prefix key with groupKey (if provided)
                    const fullKey = createFullChildKey(groupKey, childKey)
                    return <div className={'ViewPropertyGroup-SingleProperty'} key={fullKey}>
                        <ViewChatGPTResult propertyDescription={childDescription}
                                           showAdvanced={showAdvanced}
                                           propertyKey={fullKey}
                                           key={fullKey}
                                           className={'property'}
                                           editable={isEditable}
                        />
                        {showSeparator && <div className={'horizontal-separator'}/>}
                    </div>
                })
            }
        </div>
    } else {
        body = <div className={`properties properties-hidden-note clickable`}
                    onClick={() => setExpanded(true)}
                    role={'button'}
        >
            [...] {childrenEntries.length} Kategorien ausgeblendet. Klicken zum einblenden.
        </div>
    }

    return (
        <div {...props} className={`ViewPropertyGroup ` + props.className ?? ''}>
            {
                showHeading &&
                <div className={'header'}>
                    <div className={'header-title clickable'} onClick={() => setExpanded(!expanded)}>
                        <h5>
                            {propertyDescription.displayName}
                        </h5>
                        <ExpandButton expanded={expanded} setExpanded={setExpanded}/>
                    </div>
                    <div>{propertyDescription.description}</div>
                </div>
            }
            <div className={`body ${indentProperties && 'indent'}`}>
                {body}
                {indentProperties && <div className={'bottom-indent-separator'}/>}
            </div>
        </div>
    )
}

export default ViewPropertyGroup;