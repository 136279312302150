import "./BotIcon.scss"
import React, {HTMLAttributes} from 'react';
import {FaGear, FaRobot} from "react-icons/fa6";

const DEFAULT_SIZE: string = "20pt"

interface BotIconProps extends HTMLAttributes<HTMLDivElement> {
    isLoading?: boolean,
    name?: string,
    size?: string
}

function BotIcon({isLoading, name, size, ...props}: BotIconProps) {
    const roboterIconSize = size ?? DEFAULT_SIZE

    return <div {...props} className={'BotIcon ' + props.className ?? ''}>
        <div className={'roboter'}>
            <FaRobot aria-label={'Roboter'} className={'roboter-icon'} style={{
                fontSize: roboterIconSize,
            }}/>
            {
                isLoading && <div className={'gear'}>
                    <FaGear aria-label={'lädt'} className={'gear-icon rotating'} style={{
                        fontSize: `calc(${roboterIconSize} / 2.7)`
                    }}/>
                </div>
            }
        </div>
        {
            name !== undefined &&
            <div className={'label'}>
                {name}
            </div>
        }
    </div>
}

export default BotIcon;