import {createBrowserRouter, Navigate} from "react-router-dom";
import App from "../App";
import Twitter from "../components/Twitter/Twitter";
import React from "react";
import SubsumeTweet from "../pages/SubsumeTweet/SubsumeTweet";
import QuerySwish from "../pages/QuerySwish/QuerySwish";
import ViewResult from "../pages/ViewResult/ViewResult";
import Input from "../pages/Input/Input";
import FOSSCompliance from "../pages/FOSSCompliance/FOSSCompliance";


export const routes = {
    twitter: {
        input: "/x/input",
        subsume: "/x/subsume",
        querySwish: "/x/evaluate",
        result: "/x/result",
    },
    compliance: {
        foss: "/foss"
    }
}

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                index: true,
                element: <Navigate to={'./x'} replace={true}/>
            },
            {
                path: 'x',
                element: <Twitter/>,
                children: [
                    {
                        index: true,
                        element: <Navigate to={'./input'} replace={true}/>
                    },
                    {
                        path: 'input',
                        element: <Input/>
                    },
                    {
                        path: 'subsume',
                        element: <SubsumeTweet/>
                    },
                    {
                        path: 'evaluate',
                        element: <QuerySwish/>
                    },
                    {
                        path: 'result',
                        element: <ViewResult/>
                    }
                ]
            },
            {
                path: routes.compliance.foss,
                element: <FOSSCompliance/>
            }
        ]
    }
])