import "./ViewBotMessage.css"
import React, {HTMLAttributes} from 'react';
import BotIcon from "../../icons/BotIcon/BotIcon";

interface ViewBotMessageProps extends HTMLAttributes<HTMLDivElement> {
    isLoading?: boolean
}

function ViewBotMessage({isLoading, ...props}: ViewBotMessageProps) {
    const className = 'ViewBotMessage ' + (props.className ?? '')

    return <div {...props} className={className}>
        <div>
            <BotIcon className={'ViewBotMessage-Avatar'}
                     size={'50px'}
                     isLoading={isLoading}
            />
        </div>
        <div className={'Message-bot ViewBotMessage-content'}>
            {props.children}
        </div>
    </div>
}

export default ViewBotMessage;