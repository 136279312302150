import "./PopUp.scss"
import React, {HTMLAttributes, ReactNode, useEffect, useRef, useState} from 'react';

interface PopupProps extends HTMLAttributes<HTMLDialogElement> {
    header: ReactNode,
    closeButton: ReactNode,
    showDelay?: number
}

function PopUp({header, closeButton, showDelay, children, ...props}: PopupProps) {
    const className = 'PopUp rounded-container ' + (props.className ?? '')
    const dialogRef = useRef<HTMLDialogElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false)

    // NOTE: I use both the .showModal method and an internal state (isOpen) to handle the showing and hiding.
    // This is to support the native support of modals via showModal and still allowing actually removing the object
    // from the DOM with the isOpen state.
    // If I would just use the open attribute for the dialog element, I would lose the default browser support for
    // modals and would have to implement that by myself.
    // However, only using the close() method will not remove the element from the DOM => isOpen state
    // See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
    //      "Dialogs that are displayed using the open attribute are non-modal."

    useEffect(() => {
        if (dialogRef.current === null) return
        setTimeout(() => {
            console.log(dialogRef.current)
            setIsOpen(true)
            dialogRef.current?.showModal()
        }, showDelay ?? 1000);
    }, [dialogRef.current]);

    if (!isOpen) return <dialog ref={dialogRef} className={'hidden'} aria-hidden={true}/>;

    return <dialog {...props} className={className} ref={dialogRef} id={'disclaimer'} onClose={() => {
        setIsOpen(false)
    }}>
        <header className={'PopUp-header'}>
            <h1>{header}</h1>
        </header>
        {children}
        <button className="PopUp-close-button btn btn-primary"
                onClick={() => {
                    dialogRef.current?.close()
                    setIsOpen(false)
                }}
                autoFocus={true}
                id={'closeDisclaimer'}
        >
            {closeButton}
        </button>
    </dialog>
}

export default PopUp;