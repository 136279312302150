import "./FOSSCompliance.css"
import React, {HTMLAttributes} from 'react';
import {Button} from "react-bootstrap";

interface FOSSComplianceProps extends HTMLAttributes<HTMLDivElement> {
}

function FOSSCompliance({...props}: FOSSComplianceProps) {
    const className = 'FOSSCompliance Main-content ' + (props.className ?? '')

    return <div {...props} className={className}>
        <div>
            <h3>Informationen bezüglich der Nutzung von Opensource-Komponenten</h3>
            <p>
                Die IsThisHatespeech Website nutzt Opensource-Komponenten.
                Über den folgenden Link können Sie den Sourcecode dieser Komponenten beziehen und die jeweiligen
                Lizenzinformationen einsehen:
            </p>
            <a href={"https://nx11941.your-storageshare.de/s/deizXdq2DqZnazZ/download/IsThisHatespeech_frontend_node_modules.zip"}>
                https://nx11941.your-storageshare.de/s/deizXdq2DqZnazZ/download/IsThisHatespeech_frontend_node_modules.zip
            </a>
        </div>

        <Button href={'/'} variant={'primary'}>
            Zurück zur Startseite
        </Button>
    </div>
}

export default FOSSCompliance;