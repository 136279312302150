import "./ViewResult.scss"
import React, {useEffect, useRef, useState} from 'react';
import TwitterPipeline from "../../components/LoadingAnimations/TwitterPipeline/TwitterPipeline";
import {useEvaluationResult} from "../../hooks/twitterDataHooks/useEvaluationResult";
import {useFactDescription} from "../../hooks/twitterDataHooks/useFactDescription";
import {useExpertMode} from "../../hooks/settingsCTXHooks/useExpertMode";
import MarkQualityInput from "../../components/MarkQualityInput/MarkQualityInput";
import ExpandableText from "../../components/ExpandableText/ExpandableText";
import ViewParagraphResult, {ViewParagraphResultProps} from "../../components/ViewParagraphResult/ViewParagraphResult";
import _ from "lodash";
import ChatGPTResult from "../../components/ChatGPT/ChatGPTResult/ChatGPTResult";
import ResultLegend from "../../components/ResultLegend/ResultLegend";
import IntersectionalBot from "../../components/intersectionalBot/IntersectionalBot";
import JunBotMarkdown from "../../components/JunBotMarkdown/JunBotMarkdown";
import {useAuth} from "react-oidc-context";
import SurveyLink from "../../components/descriptions/SurveyLink/SurveyLink";
import ResultSideBar from "../../components/ResultSideBar/ResultSideBar";

function ViewResult() {
    const [expertMode] = useExpertMode()
    const auth = useAuth()
    const isLoggedIn = auth.isAuthenticated
    const [result] = useEvaluationResult()
    const [factDescription] = useFactDescription()
    const [disclaimerText, setDisclaimerText] = useState<string>()
    const intersectionalBotRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const disclaimer = require("./Disclaimer.md");

        fetch(disclaimer)
            .then(response => {
                return response.text()
            })
            .then(setDisclaimerText)
    }, [])

    if (result === null) {
        return <div className={'ViewResult'}>
            Kein Ergebnis gefunden. Bitte gehen Sie zurück zu Schritt 1.
        </div>
    }

    let paragraphs: ViewParagraphResultProps['paragraph'][] = ["stgb185", "stgb186", "stgb187"]
    paragraphs = _.sortBy(paragraphs, [(paragraph) => {
        switch (result[paragraph].value) {
            case "true":
                return -1
            case "unknown":
                return 1
            case "false":
                return 0
        }
    }])

    return (
        <div className={'ViewResult'}>
            <div className={'ViewResult-content'}>
                <h1>Die Ersteinschätzung der KI – verknüpft mit unserer juristischen Wissensdatenbank</h1>
                <TwitterPipeline isLoading={false} curStep={'result'} factDescription={factDescription}
                                 viewFactDescriptionTextOverwrites={{
                                     tweetHeading: "Bewerteter Tweet:",
                                     textHeading: "Bewerteter Inhalt:"
                                 }}/>
                <div className={'horizontal-separator'} role={'presentation'}/>

                {
                    expertMode && isLoggedIn &&
                    <MarkQualityInput/>
                }

                <h1>
                    Rechtliche Einschätzung
                </h1>
                <p>
                    Wir haben geprüft, ob die Äußerung eine rechtsverletzende Beleidigung (§ 185 StGB), Üble Nachrede (§
                    186
                    StGB) oder Verleumdung (§ 187 StGB) verwirklichen könnte. <strong>Bitte
                    beachte:</strong> isthishatespeech kann und soll eine <strong>Rechtsberatung nicht
                    ersetzen</strong>.
                </p>

                <ExpandableText titleElement={<span>Legende zur Auswertung</span>}>
                    <ResultLegend/>
                </ExpandableText>

                {
                    paragraphs.map(paragraph => <ViewParagraphResult paragraph={paragraph} key={paragraph}/>)
                }


                <div className={'horizontal-separator'}/>

                <h2>Disclaimer: Menschliches Bauchgefühl vor Künstlicher Intelligenz</h2>
                <JunBotMarkdown>
                    {disclaimerText}
                </JunBotMarkdown>


                <div className={'horizontal-separator'}/>

                <IntersectionalBot botRef={intersectionalBotRef}/>

                <SurveyLink/>

                <div className={'horizontal-separator'}/>

                <h2>Rechtsverletzender Inhalt erkannt: was kannst du nun tun?</h2>

                <ExpandableText
                    titleElement={<span>So erstellst Du Dein <strong>rechtssicheres Beweismittel</strong></span>}>
                    Ein Screenshot sollte Folgendes enthalten:
                    <ul>
                        <li>den fraglichen Inhalt (Kommentar, Video, Audiodatei, …),</li>
                        <li>Datum und Uhrzeit des Inhalts,</li>
                        <li>Datum und Uhrzeit der Erstellung des Screenshots,</li>
                        <li>(User-)Namen des*der mutmaßlichen Täter*in,</li>
                        <li>Kontext des Inhalts,</li>
                        <li>gegen wen richtet sich der Hasskommentar?</li>
                    </ul>

                    Im Internet frei verfügbare Tools, können das Sichern von Inhalten leichter machen, zum Beispiel das
                    Angebot von <a href={'https://www.netzbeweis.com'} target={'_blank'}>NetzBeweis</a>.

                    Zu allen weiteren Beweismitteln empfehlen wir Dir diesen <a
                    href={'https://hateaid.org/rechtssichere-screenshots/'} target={'_blank'}>Beitrag von HateAid</a>,
                    in
                    dem einfach und verständlich in Videos die Erstellung eines rechtssicheren Beweismittels,
                    unter der Berücksichtigung der Besonderheiten der verschiedenen Plattformen, erklärt wird.
                </ExpandableText>

                <ExpandableText
                    titleElement={<span>Du bist nicht allein! Diese <strong>Hilfsorganisationen</strong> können dir helfen:</span>}>
                    <ul>
                        <li><a href={'https://hateaid.org/'} target={'_blank'}>HateAid</a></li>
                        <li><a href={'https://www.das-nettz.de/'} target={'_blank'}>DAS NETTZ</a></li>
                        <li><a href={'https://www.klicksafe.de/hate-speech'} target={'_blank'}>Klicksafe</a></li>
                        <li><a href={'https://no-hate-speech.de/de/'} target={'_blank'}>Nohatespeech</a></li>
                        <li><a href={'https://www.ichbinhier.eu/'} target={'_blank'}>Ichbinhier</a></li>
                        <li><a href={'https://www.netzcourage.ch/'} target={'_blank'}>Netzcourage (Schweiz)</a></li>
                        <li><a href={'https://kompetenznetzwerk-hass-im-netz.de/'} target={'_blank'}>Hass im Netz</a>
                        </li>
                        <li><a href={'https://kompetenznetzwerk-hass-im-netz.de/'} target={'_blank'}>Facing Facts!</a>
                        </li>
                    </ul>

                    Hatespeech kann körperliche und seelische Spuren hinterlassen, hier findest Du ein offenes Ohr:

                    <ul>
                        <li>Telefonseelsorge: 0800 111 01 11 oder 0800 111 02 22</li>
                        <li>Für russischsprachige Personen: 0511 123 588 97</li>
                        <li>Hotline für Personen jüdischen Glaubens auf Hebräisch: 0800 000 1642 (von 20-22 Uhr)</li>
                        <li>Hotline für Personen muslimischen Glaubens: 030 44 35 09 821 (16-24 Uhr)</li>
                        <li>Oder per Online-Chat, z. B. unter
                            https://www.ekd.de/Online-Chat-und-E-Mail-Seelsorge-15585.htm
                        </li>
                    </ul>
                </ExpandableText>

                <ExpandableText
                    titleElement={<span>Was sagt unser <strong>Anwalt des Vertrauens</strong> Chan-jo Jun?</span>}>
                    Du möchtest mehr Informationen?
                    Dann schau doch mal in dieses Video des Kanzleiinhabers Chan-jo Jun der Kanzlei JUN Rechtsanwälte.
                    Im Video geht er vertieft auf die straf- und zivilrechtlichen Möglichkeiten Betroffener von
                    Hasskommentaren ein:

                    <p>
                        <a href={'https://www.youtube.com/watch?v=xqowqGIJY7Y'}
                           target={'_blank'}>https://www.youtube.com/watch?v=xqowqGIJY7Y</a>
                    </p>
                </ExpandableText>

                <ExpandableText titleElement={<span><strong>Auswertung</strong> erneut ansehen</span>}>
                    <ChatGPTResult editable={false}/>
                </ExpandableText>
            </div>
            <ResultSideBar className={'ViewResult-sidebar'} intersectionalBotRef={intersectionalBotRef}/>
        </div>
    )
}

export default ViewResult;