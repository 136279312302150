import "./UserAnswer.scss"
import React, {HTMLAttributes} from 'react';
import {AnswerMessage} from "../../IntersectionalBot";

interface AnswerProps extends HTMLAttributes<HTMLDivElement> {
    answer: AnswerMessage
}

function UserAnswer({answer, ...props}: AnswerProps) {
    const className = 'UserAnswer Message-user ' + (props.className ?? '')
    return (
        <div {...props} className={className}>
            {answer.answer}
        </div>
    )
}

export default UserAnswer;